import React from "react";
import Button from "../Inputs/Button";
import Divider from "../Inputs/Divider";
import { useNavigate } from "react-router-dom";
import './AboutListSmall.css';
import { SummaryImageListItem } from "../../data/SiteData";

interface AboutListSmallProps {
    Title: string,
    Message: string,
    ShowButton: boolean,
    ButtonText: string,
    Items: SummaryImageListItem[]
}

const AboutListSmall = (props: AboutListSmallProps) => {
    let navigate = useNavigate();
    
    return (
        <div className="about-list-small">
            <div className="centered">
                <img src={require("../../images/screenshots.png")} alt="previews" className="centered" loading="lazy" />
            </div>
            <div className="d-flex flex-column">
                <div className="row">
                    {props.Items.map(item => {
                        return (
                            <div className="col-12 col-lg-4 centered">
                                <div className="list-item centered">
                                    <div className={`bi ${item.Source}`}></div>
                                    <h3 className="centered">{item.Title}</h3>
                                    <div>{item.Details}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="centered description">
                    {props.Message}
                </div>
                {props.ShowButton && <Button success text={props.ButtonText} onClick={() => navigate("/about")} />}
            </div>
        </div>
    );
}

export default AboutListSmall;