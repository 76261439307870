import React from "react";
import Button from "../Inputs/Button";
import Divider from "../Inputs/Divider";
import { useNavigate } from "react-router-dom";
import './WelcomeFull.css';

interface WelcomeFullProps {
    Title: string,
    Message: string,
    ShowButton: boolean,
    ButtonText: string
}

const WelcomeFull = (props: WelcomeFullProps) => {
    let navigate = useNavigate();
    
    return (
        <div className="welcome-full">
            <div className="overlay d-flex flex-column justify-content-center align-items-center">
                <div className="content">
                    <h1>{props.Title}</h1>
                    <Divider />
                    <h5>{props.Message}</h5>
                    {props.ShowButton && <Button Inverted text={props.ButtonText} onClick={() => navigate("/contact")} />}
                </div>
            </div>
        </div>
    );
}

export default WelcomeFull;