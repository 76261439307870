import Button from "../components/Inputs/Button";

export interface ImageListItem {
    Title: string,
    Source: any
}

export interface SummaryListItem {
    Title: string,
    Details: JSX.Element
}

export interface SummaryImageListItem {
    Title: string,
    Details: JSX.Element,
    Source: any
}

export const StringData = {
    HeaderTitle: "Shekl",
    WelcomeTitle: "Take control of your church budget",
    WelcomeMessage: "You didn't get into the ministry to balance spreadsheets, so why not use a budgeting solution that's simple and intuitive? Get back to what matters most.",
    WelcomeButton: "Contact",
    AboutTitle: "",
    AboutMessage: "",
    AboutButton: "About",
    AboutUsTitle: "About Us",
    ProductSummaryTitle: "How it Works",
    ContactUsTitle: "",
    ContactUsHead: "Contact Us",
    ContactUsMessage: "To get started with Shekl today, please contact us by phone at (903) 689-2725 or by email at freelifeapplications@gmail.com",
    ContactUsHead2: "",
    ContactUsMessage2: "",
    FooterTitle: "FreeLife Applications, LLC",
    PhoneNumber: "(903) 689-2725",
    Email: "freelifeapplications@gmail.com",
    Copywright: "Copyright © 2023 FreeLife Applications - All Rights Reserved."
}

export const SiteLinks = [
    {
        id: 4,
        path: "/",
        text: "Home"
    },
    {
        id: 1,
        path: "/pricing",
        text: "Plans & Pricing"
    },
    {
        id: 5,
        path: "/about",
        text: "About"
    },
    {
        id: 2,
        path: "/contact",
        text: "Contact Us"
    },
    {
        id: 2,
        path: "https://church.sheklbudget.com",
        text: "Login/Register"
    }
];

export const AvailableProducts: ImageListItem[] = [
    {
        Title: "Product 1",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Product 2",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Product 3",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Product 4",
        Source: require("../images/code.jpg"),
    }
];

export const SoonToComeProducts: ImageListItem[] = [
    {
        Title: "Item1",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Item2",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Item3",
        Source: require("../images/code.jpg"),
    }
];

export const WhatWeOffer: ImageListItem[] = [
    {
        Title: "Item 1",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Item 2",
        Source: require("../images/code.jpg"),
    },
    {
        Title: "Item 3",
        Source: require("../images/code.jpg"),
    }
];

export const AboutUsItems: SummaryListItem[] = [
    {
        Title: "About Shekl",
        Details: (<>
            Shekl was first made as a budgeting application to handle the personal finances of our own family. Then, to our surprise, we found out our church had a need for a budgeting software app.<br /><br />
            We never could have imagined that what we made for ourselves could compete with the many budgeting applications that currently existed. But what we found was a complete hole in the market when it came to budgeting software geared towards churches. We stumbled upon something we couldn't have known on our own, but God lead our steps before we even knew where he was leading us.<br /><br />
            We got to work right away on our personal app to make it something the church could use. We were like David and all these large budgeting apps were like Goliath. And yet somehow, we had something to offer, that the market didn't have for churches. We just want make something churches can really use and make their own. And so, with God's help, we did!</>)
    },
    {
        Title: "About FreeLife Applications, LLC",
        Details: (
            <>
                We are husband and wife team who God called to make software for churches. So, with 12 plus years of software experience, we stepped out in faith to start our own business.<br /><br />
                Now we use the gifts and knowledge God gave us under FreeLife Applications to serve the church by assisting with its software needs. Many parts of this vision are currently being formed.<br /><br />
                Shekl is just one facet of the dream. How we do it is also simple. We ask the church, "What are your needs?" And then we do our best to fill them.<br /><br />
                We are a for profit ministry, but profit isn't our first priority. We want to see the church as a whole thrive and grow using what is familiar to this generation, technology. If technology can be used to prosper big business, why not also use it to bless church administration and church member ministries and businesses?
            </>)
    }
];

export const AboutUsImageItems: SummaryImageListItem[] = [
    {
        Title: "Simple, Intuitive Interface",
        Details: (
        <>
            Get in and get out. With Shekl's focused design, there's no need to fiddle with complicated menus filled with options you don't need or go through complex training to figure out how to use the features you do.
        </>),
        Source: "bi-check2-square"
    },
    {
        Title: "Deeply Customizable",
        Details: (
        <>
            We understand that everyone has unique needs, and those needs can change from one period to the next.<br />
            With Shekl, you can set your budgeting periods and make adjustments as your needs change without having to create new budgets or lose track of the changes over time.
        </>),
        Source: "bi-check2-square"
    },
    {
        Title: "Clean, Attractive Design",
        Details: (
            <>
            There's no reason why a budgeting app has to look terrible or intimidating.<br />
            With Shekl, we keep the design clean and make it clear where we've placed all the features you need.
        </>),
        Source: "bi-check2-square"
    }
];

export const HowItWorksImageItems: SummaryImageListItem[] = [
    {
        Title: "Assign Budgets",
        Details: (
        <>
            Invite users to your Shekl Church Family account and start assigning budgets right away. Each budget can be assigned to any number of Shekl users, and each user can be assigned to any number of budgets. Shekl users will be able to see the shared progress of all budgets that they are assigned to.
        </>),
        Source: require("../images/budget.jpg")
    },
    {
        Title: "Submit Expenses",
        Details: (
        <>
            With Shekl, users can submit expenses, upload receipts and even request reimbursement. Users are also notified if they forget to upload a receipt or if a transaction is still awaiting reimbursement. Expenses on Shekl can be assigned to any budget the user has access to, and even assigned a category within that budget.
        </>),
        Source: require("../images/expense.jpg")
    },
    {
        Title: "Reports",
        Details: (
        <>
            Shekl will soon include a variety of reporting options. You will be able to generate reports on Shekl over one or more budgeting periods, select which people and budgets to include and even choose how the data should be presented.
        </>),
        Source: require("../images/report.jpg")
    }
];
