import React from "react";

const Divider = (props: any) => {
    return (
            <div className="section-divider text-success">
                <span>{props.text}</span>
            </div>
    );
};

export default Divider;