import React from "react";
import './Products.css';
import Button from "../components/Inputs/Button";
import { useNavigate } from "react-router-dom";

const Products = (props: any) => {
    let navigate = useNavigate();
   
    return (
        <div className="full-page">
        <div className="page_header"></div>
        <div className="products-pricing">
            <h1 className="centered fw-bolder">Choose your pricing plan</h1>
            <div className="row centered">
                <div className="col-12 col-lg-4">
                    <h3>Basic Monthly</h3>
                    <h1>$20</h1>
                    Every Month<br />
                    7 day free trial<br /><br /><br />
                    Includes 2 User Accounts<br /><br />
                    2 of the 2 Users can be Admins<br /><br />
                    Unlimited Budgets<br /><br />
                    Unlimited Expenses<br /><br />
                    Full Access to Reporting<br />(coming soon)<br /><br />
                    Option to Add More Users for<br />$4/mo each<br /><br /><br />
                    <Button text="Contact Us" onClick={() => navigate("/contact")} />
                </div>
                <div className="col-12 col-lg-4">
                    <h3>Small Church Yearly</h3>
                    <h1>$360</h1>
                    Every Year<br />
                    7 day free trial<br /><br /><br />
                    Includes 5 User Accounts<br /><br />
                    2 of the 5 Users can be Admins<br /><br />
                    Unlimited Budgets<br /><br />
                    Unlimited Expenses<br /><br />
                    Full Access to Reporting<br />(coming soon)<br /><br />
                    Option to Add More Users for<br />$4/mo each<br /><br />
                    6.2% Savings Over Basic Plan
                    <Button text="Contact Us" onClick={() => navigate("/contact")} />
                </div>
                <div className="col-12 col-lg-4">
                    <h3>Bigger Church Yearly</h3>
                    <h1>$540</h1>
                    Every Year<br />
                    7 day free trial<br /><br /><br />
                    Includes 10 User Accounts<br /><br />
                    2 of the 10 Users can be Admins<br /><br />
                    Unlimited Budgets<br /><br />
                    Unlimited Expenses<br /><br />
                    Full Access to Reporting<br />(coming soon)<br /><br />
                    Option to Add More Users for<br />$4/mo each<br /><br />
                    13.5% Savings Over Basic Plan
                    <Button text="Contact Us" onClick={() => navigate("/contact")} />
                </div>
            </div>
        </div>
        </div>
    );
}

export default Products;