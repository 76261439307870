import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import './styles/App.css';
import About from "./Pages/About";
import NotMatch from "./Pages/NotMatch";
import Footer from './components/Frame/Footer';
import Home from './Pages/Home';
import Products from './Pages/Products';
import Contact from './Pages/Contact';
import Header from './components/Frame/Header';

export var pageLoad: any = { instance: 1 };
function App() {
    return (
        <>
            <div className="App">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/pricing" element={<Products />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="*" element={<NotMatch />} />
                </Routes>
            </div>
            <Header />
            <Footer />
        </>
    );
}

export default App;
