import React from "react";
import ProductSummaryList from "../components/Modules/ImageDetailsAlternatingListSmall";
import Welcome from "../components/Modules/WelcomeFull";
import AboutSummary from "../components/Modules/AboutListSmall";
import { AboutUsImageItems, StringData, HowItWorksImageItems } from "../data/SiteData";

const Home = (props: any) => {
    return (
        <>
        <Welcome Title={StringData.WelcomeTitle} Message={StringData.WelcomeMessage} ShowButton ButtonText="Get Started" />
        <AboutSummary Title={StringData.AboutTitle} Message={StringData.AboutMessage} ShowButton={false} ButtonText={StringData.AboutButton} Items={AboutUsImageItems} />
        <ProductSummaryList Title={StringData.ProductSummaryTitle} Items={HowItWorksImageItems} />
        </>
    );
}

export default Home;