import React from "react";
import Divider from "../Inputs/Divider";
import Button from "../Inputs/Button";
import './ImageDetailsAlternatingListSmall.css';
import { SummaryImageListItem } from "../../data/SiteData";
import { useNavigate } from "react-router-dom";

export interface ImageDetailsAlternatingListSmallProps {
    Title: string,
    Items: SummaryImageListItem[]
}

const ImageDetailsAlternatingListSmall = (props: ImageDetailsAlternatingListSmallProps) => {
    let navigate = useNavigate();
    let itemCount = 0;
    
    return (
        <div className="image-details-alternating-list-small">
            <h1 className="centered">{props.Title}</h1>
            <Divider />
            <div className="d-flex flex-column">
                {props.Items.map(item => {
                    itemCount++;
                    if (itemCount % 2 === 0) {
                        return (
                            <>
                            <div className="row">
                                <div className=" d-none d-lg-block col-6 description">
                                    <div className="list-item">
                                        <h1 className="centered">{item.Title}</h1>
                                        {item.Details}
                                    </div>
                                </div>
                                <div className="centered col-12 col-lg-6 img-div">
                                    <img src={item.Source} alt={item.Title} className="centered" loading="lazy" />
                                </div>
                                <div className=" d-lg-none col-12 description">
                                    <div className="list-item">
                                        <h1 className="centered">{item.Title}</h1>
                                        {item.Details}
                                    </div>
                                </div>
                            </div>
                            </>
                        );
                    }
                    else {
                        return (
                            <>
                            <div className="row">
                                <div className="centered col-12 col-lg-6 img-div">
                                    <img src={item.Source} alt={item.Title} className="centered" loading="lazy" />
                                </div>
                                <div className=" col-12 col-lg-6 description">
                                    <div className="list-item">
                                        <h1 className="centered">{item.Title}</h1>
                                        {item.Details}
                                    </div>
                                </div>
                            </div>
                            </>
                        );
                    }
                })}
            </div>
            <Divider />
            <div className="centered">
                Shekl was made with churches in mind. No more retrofitting super complicated business software to handle what churches want budgeted their way.<br />
                <Button Inverted text="Request Your Free Trial Today!" onClick={() => navigate("/contact")} />
            </div>
        </div>
    );
}

export default ImageDetailsAlternatingListSmall;